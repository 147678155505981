import { useState, useEffect } from 'react';
import { Breakpoints } from '../styled/constants';

const useScreenSize = () => {
  const isBrowser = typeof window !== 'undefined';
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

  const isLarge = width >= Breakpoints.desktop;
  const isMedium = width >= Breakpoints.tablet && width < Breakpoints.desktop;
  const isSmall = width < Breakpoints.tablet;

  useEffect(() => {
    if (isBrowser) {
      const handleResize = () => {
        setWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return { isLarge, isMedium, isSmall };
};

export default useScreenSize;
